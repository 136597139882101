<template>
  <div class="main-box">
    <div class="mg-bt-10">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="addGroup()">添加</el-button>
      <el-button type="danger" size="small" icon="el-icon-delete" @click="delGroup()">删除</el-button>
    </div>
    <el-table
        :data="tableData"
        ref="rfTable"
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          type="selection"
          align="center"
          width="55">
      </el-table-column>
      <el-table-column
          prop="amount"
          label="充值金额">
      </el-table-column>
      <el-table-column
          prop="send_amount"
          label="赠送金额"
          align="left"
          width="150">
      </el-table-column>
      <el-table-column
          prop="send_integral"
          label="赠送积分数"
          align="left"
          width="280">
      </el-table-column>
      <el-table-column
          prop="coupon"
          label="赠送券"
          align="left"
          width="150">
        <template slot-scope="scope">
          <div v-if="scope.row.coupon && scope.row.coupon.length>0">
            <div v-for="(item,index) in scope.row.coupon" :key="index">{{ item.title }} x{{ item.sum }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="status"
          label="状态"
          align="center"
          width="120">
        <template slot-scope="scope">
          <el-switch
              :value="scope.row.status==1?'1':'0'"
              @change="shenheGroup(scope.row)"
              active-value="1"
              inactive-value="2"
              active-text="关闭"
              inactive-text="开启">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column
          prop="ftime"
          label="添加时间"
          align="center"
          width="150">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="80">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editGroup(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10
      },
      tableData: []
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit
      }
      this.$api.marking.rechargeIndex(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    addGroup() {
      this.$router.push({name: 'marking_recharge_add'})
    },
    editGroup(item) {
      this.$router.push({name: 'marking_recharge_add', query: {id: item.id}})
    },
    delGroup() {
      var that = this
      var sel = this.$refs.rfTable.selection
      if (sel.length < 1) {
        that.tips('请选择删除项')
        return false
      }
      var ids = []
      sel.forEach(function (item) {
        ids.push(item.id)
      })
      this.$confirm('确认要删除这些数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.marking.rechargeDel({ids: ids}, function (res) {
          if (res.errcode == 0) {
            that.success('删除成功')
            that.getList()
          } else {
            that.fail(res.errmsg)
          }
        })
      }).catch(() => {

      });
    },
    shenheGroup(item) {
      this.$api.marking.rechargeShenhe({id: item.id, status: item.status == 1 ? 0 : 1}, res => {
        if (res.errcode == 0) {
          this.success(res.errmsg)
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
